import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Flex } from 'components/Flex';
import { Looper } from 'components/Looper';
import { Rhythm } from 'components/Rhythm';
import { ColorLooper } from 'docs/helpers/ColorLooper';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { Avatar, ColoredAvatar, StyledAvatar } from '../index';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="components/Avatar" title="Avatar" mdxType="PageTitle" />
    <h2 {...{
      "id": "image"
    }}>{`Image`}</h2>
    <Playground __position={1} __code={'<ThemeWrapper>\n  <Flex wrap alignItems=\"center\" direction=\"row\">\n    <Looper\n      list={[\n        \'xsmall\',\n        \'small\',\n        \'medium\',\n        \'large\',\n        \'xlarge\',\n        \'2xlarge\',\n        \'3xlarge\',\n        \'4xlarge\',\n        \'5xlarge\',\n      ]}\n      render={size => (\n        <Rhythm key={size} m={2}>\n          <Avatar\n            imgSrc=\"/public/images/avatar.png\"\n            initials=\"P\"\n            size={size}\n          />\n        </Rhythm>\n      )}\n    />\n  </Flex>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Looper,
      Rhythm,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Avatar,
      ColoredAvatar,
      StyledAvatar,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Flex wrap alignItems="center" direction="row" mdxType="Flex">
      <Looper list={['xsmall', 'small', 'medium', 'large', 'xlarge', '2xlarge', '3xlarge', '4xlarge', '5xlarge']} render={size => <Rhythm key={size} m={2} mdxType="Rhythm">
            <Avatar imgSrc="/public/images/avatar.png" initials="P" size={size} mdxType="Avatar" />
          </Rhythm>} mdxType="Looper" />
    </Flex>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "actionable-image"
    }}>{`Actionable image`}</h2>
    <Playground __position={2} __code={'<ThemeWrapper>\n  <Flex wrap alignItems=\"center\" direction=\"row\">\n    <Looper\n      list={[\n        \'xsmall\',\n        \'small\',\n        \'medium\',\n        \'large\',\n        \'xlarge\',\n        \'2xlarge\',\n        \'3xlarge\',\n        \'4xlarge\',\n        \'5xlarge\',\n      ]}\n      render={size => (\n        <Rhythm key={size} m={2}>\n          <Avatar\n            actionable\n            imgSrc=\"/public/images/avatar.jpg\"\n            initials=\"P\"\n            size={size}\n          />\n        </Rhythm>\n      )}\n    />\n  </Flex>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Looper,
      Rhythm,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Avatar,
      ColoredAvatar,
      StyledAvatar,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Flex wrap alignItems="center" direction="row" mdxType="Flex">
      <Looper list={['xsmall', 'small', 'medium', 'large', 'xlarge', '2xlarge', '3xlarge', '4xlarge', '5xlarge']} render={size => <Rhythm key={size} m={2} mdxType="Rhythm">
            <Avatar actionable imgSrc="/public/images/avatar.jpg" initials="P" size={size} mdxType="Avatar" />
          </Rhythm>} mdxType="Looper" />
    </Flex>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "initials"
    }}>{`Initials`}</h2>
    <Playground __position={3} __code={'<ThemeWrapper>\n  <Flex direction=\"column\">\n    <div>\n      <h3>Primary color</h3>\n      <Flex wrap alignItems=\"center\" direction=\"row\">\n        <Rhythm m={2}>\n          <Looper\n            list={[\n              \'xsmall\',\n              \'small\',\n              \'medium\',\n              \'large\',\n              \'xlarge\',\n              \'2xlarge\',\n              \'3xlarge\',\n              \'4xlarge\',\n              \'5xlarge\',\n            ]}\n            render={size => (\n              <Rhythm key={size} m={2}>\n                <Avatar color=\"primary\" initials=\"P\" size={size} />\n              </Rhythm>\n            )}\n          />\n        </Rhythm>\n      </Flex>\n    </div>\n    <div>\n      <h3>Neutral color</h3>\n      <Flex wrap alignItems=\"center\" direction=\"row\">\n        <Rhythm m={2}>\n          <Looper\n            list={[\n              \'xsmall\',\n              \'small\',\n              \'medium\',\n              \'large\',\n              \'xlarge\',\n              \'2xlarge\',\n              \'3xlarge\',\n              \'4xlarge\',\n              \'5xlarge\',\n            ]}\n            render={size => (\n              <Rhythm key={size} m={2}>\n                <Avatar color=\"neutral\" initials=\"P\" size={size} />\n              </Rhythm>\n            )}\n          />\n        </Rhythm>\n      </Flex>\n    </div>\n  </Flex>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Looper,
      Rhythm,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Avatar,
      ColoredAvatar,
      StyledAvatar,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Flex direction="column" mdxType="Flex">
      <div>
        <h3>Primary color</h3>
        <Flex wrap alignItems="center" direction="row" mdxType="Flex">
          <Rhythm m={2} mdxType="Rhythm">
            <Looper list={['xsmall', 'small', 'medium', 'large', 'xlarge', '2xlarge', '3xlarge', '4xlarge', '5xlarge']} render={size => <Rhythm key={size} m={2} mdxType="Rhythm">
                  <Avatar color="primary" initials="P" size={size} mdxType="Avatar" />
                </Rhythm>} mdxType="Looper" />
          </Rhythm>
        </Flex>
      </div>
      <div>
        <h3>Neutral color</h3>
        <Flex wrap alignItems="center" direction="row" mdxType="Flex">
          <Rhythm m={2} mdxType="Rhythm">
            <Looper list={['xsmall', 'small', 'medium', 'large', 'xlarge', '2xlarge', '3xlarge', '4xlarge', '5xlarge']} render={size => <Rhythm key={size} m={2} mdxType="Rhythm">
                  <Avatar color="neutral" initials="P" size={size} mdxType="Avatar" />
                </Rhythm>} mdxType="Looper" />
          </Rhythm>
        </Flex>
      </div>
    </Flex>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "actionable-initials"
    }}>{`Actionable initials`}</h2>
    <Playground __position={4} __code={'<ThemeWrapper>\n  <Flex direction=\"column\">\n    <div>\n      <h3>Primary color</h3>\n      <Flex wrap alignItems=\"center\" direction=\"row\">\n        <Looper\n          list={[\n            \'xsmall\',\n            \'small\',\n            \'medium\',\n            \'large\',\n            \'xlarge\',\n            \'2xlarge\',\n            \'3xlarge\',\n            \'4xlarge\',\n            \'5xlarge\',\n          ]}\n          render={size => (\n            <Rhythm key={size} m={2}>\n              <Avatar actionable color=\"primary\" initials=\"P\" size={size} />\n            </Rhythm>\n          )}\n        />\n      </Flex>\n    </div>\n    <div>\n      <h3>Neutral color</h3>\n      <Flex wrap alignItems=\"center\" direction=\"row\">\n        <Looper\n          list={[\n            \'xsmall\',\n            \'small\',\n            \'medium\',\n            \'large\',\n            \'xlarge\',\n            \'2xlarge\',\n            \'3xlarge\',\n            \'4xlarge\',\n            \'5xlarge\',\n          ]}\n          render={size => (\n            <Rhythm key={size} m={2}>\n              <Avatar actionable color=\"neutral\" initials=\"P\" size={size} />\n            </Rhythm>\n          )}\n        />\n      </Flex>\n    </div>\n  </Flex>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Looper,
      Rhythm,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Avatar,
      ColoredAvatar,
      StyledAvatar,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Flex direction="column" mdxType="Flex">
      <div>
        <h3>Primary color</h3>
        <Flex wrap alignItems="center" direction="row" mdxType="Flex">
          <Looper list={['xsmall', 'small', 'medium', 'large', 'xlarge', '2xlarge', '3xlarge', '4xlarge', '5xlarge']} render={size => <Rhythm key={size} m={2} mdxType="Rhythm">
                <Avatar actionable color="primary" initials="P" size={size} mdxType="Avatar" />
              </Rhythm>} mdxType="Looper" />
        </Flex>
      </div>
      <div>
        <h3>Neutral color</h3>
        <Flex wrap alignItems="center" direction="row" mdxType="Flex">
          <Looper list={['xsmall', 'small', 'medium', 'large', 'xlarge', '2xlarge', '3xlarge', '4xlarge', '5xlarge']} render={size => <Rhythm key={size} m={2} mdxType="Rhythm">
                <Avatar actionable color="neutral" initials="P" size={size} mdxType="Avatar" />
              </Rhythm>} mdxType="Looper" />
        </Flex>
      </div>
    </Flex>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "variations"
    }}>{`Variations`}</h2>
    <h3 {...{
      "id": "contrast-initials"
    }}>{`Contrast initials`}</h3>
    <Playground __position={5} __code={'<ThemeWrapper contrast>\n  <Flex wrap alignItems=\"center\" direction=\"row\">\n    <Looper\n      list={[\n        \'xsmall\',\n        \'small\',\n        \'medium\',\n        \'large\',\n        \'xlarge\',\n        \'2xlarge\',\n        \'3xlarge\',\n        \'4xlarge\',\n        \'5xlarge\',\n      ]}\n      render={size => (\n        <Rhythm key={size} m={2}>\n          <Avatar actionable contrast initials=\"P\" size={size} />\n        </Rhythm>\n      )}\n    />\n  </Flex>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Looper,
      Rhythm,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Avatar,
      ColoredAvatar,
      StyledAvatar,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper contrast mdxType="ThemeWrapper">
    <Flex wrap alignItems="center" direction="row" mdxType="Flex">
      <Looper list={['xsmall', 'small', 'medium', 'large', 'xlarge', '2xlarge', '3xlarge', '4xlarge', '5xlarge']} render={size => <Rhythm key={size} m={2} mdxType="Rhythm">
            <Avatar actionable contrast initials="P" size={size} mdxType="Avatar" />
          </Rhythm>} mdxType="Looper" />
    </Flex>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "colored-initials"
    }}>{`Colored initials`}</h3>
    <Playground __position={6} __code={'<ThemeWrapper withThemeId>\n  {({ themeId }) => (\n    <Rhythm my={2}>\n      <Flex direction=\"column\">\n        <ColorLooper\n          group=\"primary\"\n          render={({ id }) => (\n            <Flex wrap alignItems=\"center\" direction=\"row\" key={id}>\n              <Looper\n                list={[\n                  \'xsmall\',\n                  \'small\',\n                  \'medium\',\n                  \'large\',\n                  \'xlarge\',\n                  \'2xlarge\',\n                  \'3xlarge\',\n                  \'4xlarge\',\n                  \'5xlarge\',\n                ]}\n                render={size => (\n                  <Rhythm key={`${id}-${size}`} m={2}>\n                    <ColoredAvatar\n                      actionable\n                      colorId={id}\n                      initials=\"P\"\n                      size={size}\n                    />\n                  </Rhythm>\n                )}\n              />\n            </Flex>\n          )}\n          themeId={themeId}\n        />\n      </Flex>\n    </Rhythm>\n  )}\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Looper,
      Rhythm,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Avatar,
      ColoredAvatar,
      StyledAvatar,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper withThemeId mdxType="ThemeWrapper">
    {({
          themeId
        }) => <Rhythm my={2} mdxType="Rhythm">
        <Flex direction="column" mdxType="Flex">
          <ColorLooper group="primary" render={({
              id
            }) => <Flex wrap alignItems="center" direction="row" key={id} mdxType="Flex">
                <Looper list={['xsmall', 'small', 'medium', 'large', 'xlarge', '2xlarge', '3xlarge', '4xlarge', '5xlarge']} render={size => <Rhythm key={`${id}-${size}`} m={2} mdxType="Rhythm">
                      <ColoredAvatar actionable colorId={id} initials="P" size={size} mdxType="ColoredAvatar" />
                    </Rhythm>} mdxType="Looper" />
              </Flex>} themeId={themeId} mdxType="ColorLooper" />
        </Flex>
      </Rhythm>}
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "styled-initials"
    }}>{`Styled initials`}</h3>
    <Playground __position={7} __code={'<ThemeWrapper>\n  <Flex wrap alignItems=\"center\" direction=\"row\">\n    <Looper\n      list={[\n        \'xsmall\',\n        \'small\',\n        \'medium\',\n        \'large\',\n        \'xlarge\',\n        \'2xlarge\',\n        \'3xlarge\',\n        \'4xlarge\',\n        \'5xlarge\',\n      ]}\n      render={size => (\n        <Rhythm key={size} m={2}>\n          <StyledAvatar\n            actionable\n            backgroundColor=\"#556270\"\n            initials=\"P\"\n            size={size}\n            textColor=\"#fff\"\n          />\n        </Rhythm>\n      )}\n    />\n  </Flex>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Looper,
      Rhythm,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Avatar,
      ColoredAvatar,
      StyledAvatar,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Flex wrap alignItems="center" direction="row" mdxType="Flex">
      <Looper list={['xsmall', 'small', 'medium', 'large', 'xlarge', '2xlarge', '3xlarge', '4xlarge', '5xlarge']} render={size => <Rhythm key={size} m={2} mdxType="Rhythm">
            <StyledAvatar actionable backgroundColor="#556270" initials="P" size={size} textColor="#fff" mdxType="StyledAvatar" />
          </Rhythm>} mdxType="Looper" />
    </Flex>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "custom-size"
    }}>{`Custom size`}</h3>
    <Playground __position={8} __code={'<ThemeWrapper>\n  <StyledAvatar\n    actionable\n    color=\"primary\"\n    initials=\"P\"\n    size=\"custom\"\n    style={{ \'--avatar-size\': \'72px\', \'--avatar-font-size\': \'24px\' }}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Looper,
      Rhythm,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Avatar,
      ColoredAvatar,
      StyledAvatar,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <StyledAvatar actionable color="primary" initials="P" size="custom" style={{
          '--avatar-size': '72px',
          '--avatar-font-size': '24px'
        }} mdxType="StyledAvatar" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <h3 {...{
      "id": "avatar"
    }}>{`Avatar`}</h3>
    <Props of={Avatar} mdxType="Props" />
    <h3 {...{
      "id": "coloredavatar"
    }}>{`ColoredAvatar`}</h3>
    <Props of={ColoredAvatar} mdxType="Props" />
    <h3 {...{
      "id": "styledavatar"
    }}>{`StyledAvatar`}</h3>
    <Props of={StyledAvatar} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      